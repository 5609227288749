
function App() {
  return (
    <div>
        <h1>Timmy Games <small>Pro</small></h1>
    </div>
  );
}

export default App;
